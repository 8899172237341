.bg_home {

    background-color: black;
}
.box_home {
}
.bg_home img{
    margin:0 auto;
    margin-bottom: 2em;
}

.moving_text_container {
    position: relative;
    width: 100%;
    height: 100px; /* Altura del contenedor */
    overflow: hidden;
  }
  
  .moving_text {
    position: absolute;
    padding: 15px;
    left: 50%;
    transform: translateX(-50%);
    animation: moveAndZoom 2s infinite alternate; /* Animación que se repite infinitamente */
  color: white;  
  font-size: 18px;
  font-weight: 800;
}
  
  @keyframes moveAndZoom {
    0% {
      transform: translateX(-50%) scale(1); /* Tamaño normal */
    }
    50% {
      transform: translateX(-50%) scale(1.5); /* Aumenta el tamaño al 150% */
    }
    100% {
      transform: translateX(-50%) scale(1); /* Vuelve al tamaño normal */
    }
  }

  @media (max-width: 768px) {
  
    
    .moving_text {
  
      font-size: 10px;
    }
  }
  