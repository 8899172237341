.succes_container {
    background: black;
}

.text{
    color: #ffc400;
}

.btn_home{
    background-color: #ffc400;
}