.contact_container {
    background-color: black;
    margin-top: 2em;
    padding: 22vh;
}

.logo img{
    margin: auto;
    width: 350px;
}
.text_contact h1 {
    color: #ffc400;
    font-size: 30px;
    text-align: center;
    margin-bottom: 2em;
}
.text_method_payment h1 {
    color: #ffc400;
    font-size: 40px;
    text-align: center;
}

.text_method_payment h2 {
    color: #ffc400;
    font-size: 30px;
    text-align: center;
    margin-bottom: 2em;
}
.contact{
    color: #ffc400;
    font-size: 30px;
    text-align: center; 
}

.email:hover {
opacity: 0.6;
}
.WhatsApp:hover  {
opacity: 0.6;

}
.payment {
    display: flex;
    justify-content: center;
    gap: 5em;
    text-align: center;
    margin-bottom: 5em;
}
.text_center {
    text-align: center;
    color: #ffc400;

}
.logo_payment img{
  width: 400px;
  height: 200px;
  display: block;
  margin: 0 auto;
}
.logo_payment {
    display: grid;
    gap: 2em;
}
.logo_payment label{
    color: #ffc400;
    font-size: 20px;
}
@media (max-width: 768px) {
    .contact_container {
        background-color: black;
        margin-top: 3em;
        padding: 15px;
    }

    .contact{
        color: #ffc400;
        font-size: 20px;
        text-align: center; 
    }

    .payment {
        display: grid;
        justify-content: center;
        gap: 5em;
        text-align: center;
    }
    
    .logo_payment img{
      width: 200px;
      height: 100px;
    }
    .logo_payment {
        display: grid;
        gap: 2em;
        color: white;
    }
}

@media screen and (max-width: 1440px) {
  

    .logo_payment img{
      width: 350px;
      height: 150px;
    }
 
}