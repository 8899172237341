.text {
    color: #ffc400;
}

.bg_profile {
    margin-top: 7em;
    background: black;
    padding: 15px;
    height: 100vh;

}

@media screen and (max-width: 1440px) {
    .bg_profile {
        margin-top: 4em;
      
    
    }
}
@media screen and (max-width: 768px) {
    .bg_profile {
        margin-top: 3em;
      
    
    }
}

