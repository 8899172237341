/* Estilos para el contenedor principal */
.dropzone {
    border: 2px dashed #ddd;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: white;
  }
  
  /* Estilos para cuando se está arrastrando un archivo */
  .dropzone-active {
    border-color: #4caf50; /* Cambia a tu color preferido */
    background-color: #f8f8f8;
  }
  
  /* Estilos para cuando no se está arrastrando ningún archivo */
  .dropzone-inactive {
    color: #666;
  }
  
  /* Estilos adicionales según sea necesario */
  .dropzone p {
    margin: 0;
    font-size: 16px;
  }
  
  .dropzone span {
    display: block;
    margin-top: 10px;
    color: #999;
    font-size: 14px;
  }
 
  .prev_mini {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100px);
    gap: 20px;
    margin-top: 20px;
  }
  
  .prev_mini img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 5px;
    opacity: 0.6;
  }