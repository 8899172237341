.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:10px;
    right:10px;
    background-color:#25d366;
    color:white;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
    text-decoration: none;
    box-shadow: 0px 0px 10px #25d366 ;
  }
  
  .whatsapp:hover{
    box-shadow: 0px 0px 50px #25d366 ;
  
  }
  
  .icon_whatsapp{
    color: white;
    position: absolute;
    margin-top: 13px;
    margin-left: -14px;
  }