
.card_container {
    margin-bottom: 2em;
}


.card {
margin-bottom: 15em;

}

.bg_logo {
   background-color: white;
    
}
.bg_black {
    background-color: black;
}
.text_destacado {
    color: #ffc400;
    font-weight: 800;
    text-align: center;
    font-size: 4em;
}

.text_title {
    color: white;
    font-weight: 800;
    text-align: center;
    font-size: 1em;
}


@media only screen and (max-width: 768px) {
    .text_destacado {
        font-size: 2em;
    }
  }